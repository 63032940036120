import ReactPlayer from 'react-player'

export const Testimonials = (props) => {
  return (
    <div id='testimonials' >
      <div className='container' >
        <div className='section-title text-center'>
          <h2>Veamos un ejemplo.</h2>
        </div>
        {/* <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <div className='testimonial'>
                    <div className='testimonial-image'>
                      {' '}
                      <img src={d.img} alt='' />{' '}
                    </div>
                    <div className='testimonial-content'>
                      <p>"{d.text}"</p>
                      <div className='testimonial-meta'> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div> */}
        <div style={{textAlign: "-webkit-center", width: "100%", height: "100%"}}>
          <ReactPlayer width= "100%"  height= "100%" url='https://youtu.be/il374575hXQ' />
        </div>
      </div>
    </div>
  )
}
