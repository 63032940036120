// import { Image } from "./image";
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center '>
      <div className='container '>
        <div className='section-title '>
          <h2>¿Cómo se ve?</h2>
          <p>
            Así lo ve tu cliente en su móvil y tú en el ordenador, tablet o móvil.
          </p>
        </div>
        {/* <div className='row'>
          <div className='portfolio-items'>
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4'>
                  <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                </div>
              ))
              : 'Loading...'}
          </div>
        </div> */}
        <div style={{textAlign: "-webkit-center"}}>
        <Carousel dynamicHeight='true' width='50%'>
          <div >
              <img src="/img/carousel/local.JPG" alt="local" width='50%'/>
              <p className="legend">App</p>
          </div>
          <div>
              <img src="/img/carousel/Mesas.JPG" alt="mesas"/>
              <p className="legend">Mesas</p>
          </div>
          <div>
              <img src="/img/carousel/domicilio.JPG" alt="domicilio"/>
              <p className="legend">Domicilio</p>
          </div>
          <div>
              <img src="/img/carousel/Tablon.JPG" alt="tablon"/>
              <p className="legend">Tablón</p>
          </div>
          <div>
              <img src="/img/carousel/productos.JPG" alt="productos"/>
              <p className="legend">Productos</p>
          </div>
          <div>
              <img src="/img/carousel/planes.JPG" alt="planes"/>
              <p className="legend">Planes</p>
          </div>
        </Carousel>
        </div>
      </div>
    </div>
  )
}
